new class inViewport {
  constructor() {
    this.els = [
      ...document.querySelectorAll('.row'),
      document.querySelector('footer img'),
      document.querySelector('footer .inner')
    ]
    this.observer = new IntersectionObserver(this.intersect, {
      rootMargin: '0px',
      threshold: 0.75
    })
    this.init()
  }

  init() {
    this.els.forEach(el => {
      this.observer.observe(el)
    })
  }

  intersect(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('inView')
      }
    })
  }
}()
